import MainPage from "./pages/MainPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Zoha from "./pages/Zoha";



const router = createBrowserRouter(
    [
    { path: "/", element: <MainPage/> },
    { path: "/zoho-domain-verification.html",element: <Zoha/> },
])



function App() {
  return < RouterProvider router={router}/>
}

export default App;
