import Footer from '../components/Footer';
import About from '../components/About';
import Hero from '../components/Hero';
import Services from '../components/Services';

function HomePage() {

    return(
        <>
            <Hero />
            <Services />
            <About />
            <Footer />
        </>
    )
}


export default HomePage