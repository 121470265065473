import React from 'react';
import People from '../assets/images/people.svg';

const About = () => {
  const jobUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLScuz_X9IBZxu3vKGWM4nGAqkOlTNcUuf1XiFGCsQalJ_9n2ig/viewform';
  return (
    <section className=' bg-darkblue text-white'>
      <img src={People} alt='img' className=' object-fill w-screen ' />

      <div className='md:px-32 p-12 space-y-8'>
        <h1 className='font-medium text-[31px] md:text-[45px] text-center md:text-justify'>
          Secure Your Next Placement With Fira.
        </h1>

        <p className='about-paragraphs'>
          At Fira, We believe in providing candidates with a high level of
          professionalism opportunities, so we vet and match them with hiring
          managers. Get access to some notable companies now.
        </p>
        <p className='about-paragraphs'>
          Got questions? Send us an email immediately. Looking for us online?
          Visit www.fira.ng
        </p>

        <div className='grid place-content-center md:place-content-start'>
          <button
            className=' font-semibold md:font-medium text-base py-2 px-6 bg-white hover:bg-underlineblue text-textGreen hover:text-white rounded-full'
            onClick={() => window.open(jobUrl, '_blank')}
          >
            Apply Here
          </button>
        </div>
      </div>
    </section>
  );
};

export default About;
