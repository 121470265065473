import React from 'react';
import Illustration from '../assets/images/service-illustration.svg';

const Services = () => {
  const talentUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSeJjGUUXtnP3_nbbBDSuasbWdPP_SUiJx_nDHgBvkQFUC9Bjw/viewform';

  return (
    <div className='md:flex md:px-32 p-12 md:space-x-6 pb-0 bg-darkblue text-offWhite'>
      <img
        src={Illustration}
        alt='lady holding a phone'
        className='w-full md:w-2/5 object-cover'
      />
      <div className='py-6 md:px-6 md:pt-0 md:w-3/5 space-y-12'>
        <h1 className='text-center font-semibold text-[42px] md:text-[37px] leading-[55px] md:leading-[45px] tracking-[-0.035em] md:tracking-tighter text-white'>
          Get to Know Us
        </h1>
        <p className='font-semibold text-[21px] leading-[29px] '>
          We Facilitate Relationships Between Businesses And Professionals.
        </p>

        <p className='font-medium text-base text-justify'>
          Our network allows our team to provide quick implementation times and
          high level of service to both clients and candidates. Working
          together, we can bring about strong relationships between companies
          and professionals, while accessing and matching high sector skills to
          companies.
        </p>
        <p className='font-medium text-base text-justify'>
          Our service cuts across all business hierarchies from administrative,
          executive, supervisory and entry-level employees, having technical and
          industry knowledge.
        </p>
        <div className='grid place-content-center md:place-content-start'>
          <button
            className='bg-white hover:bg-underlineblue font-medium text-base rounded-full text-black hover:text-white p-2 '
            onClick={() => window.open(talentUrl, '_blank')}
          >
            PARTNER WITH US
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;
