import React from 'react';
import Logo from '../assets/icons/Logo.svg';
import Lady from '../assets/images/hero-illustration.svg';

const Hero = () => {
  const jobUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLScuz_X9IBZxu3vKGWM4nGAqkOlTNcUuf1XiFGCsQalJ_9n2ig/viewform';
  const talentUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSeJjGUUXtnP3_nbbBDSuasbWdPP_SUiJx_nDHgBvkQFUC9Bjw/viewform';

  return (
    <section className='flex flex-col-reverse md:flex-row text-white'>
      <a href='/'>
        <img
          src={Logo}
          alt='logo'
          className='absolute z-10 top-12 left-12 md:left-32 p-2'
        />
      </a>

      <div className='bg-textGreen md:w-2/4 md:px-32 p-12 pt-32 '>
        <p className=' inline-block mb-10 font-bold text-6xl md:text-5xl md:mt-16 whitespace-pre-line'>
          Find credible <br /> <span className='pt-12'>talents.</span>
        </p>
        <p className='mb-20 font-medium text-base'>
          Find your dream job anywhere.
        </p>
        <div className=' flex flex-col md:flex-row gap-6 text-center mx-8 md:mx-auto'>
          <button
            className='bg-underlineblue hover:text-textGreen hover:bg-white hero-button'
            onClick={() => window.open(jobUrl, '_blank')}
          >
            SEARCHING FOR A JOB?
          </button>
          <button
            className='bg-white text-textGreen hover:text-white hover:bg-underlineblue hero-button'
            onClick={() => window.open(talentUrl, '_blank')}
          >
            LOOKING FOR A TALENT?
          </button>
        </div>
      </div>
      <img src={Lady} alt='a lady' className='md:w-2/4 object-fill' />
    </section>
  );
};

export default Hero;
