import React from 'react';
import Logo from '../assets/icons/Logo.svg';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';

const Footer = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const url =
    'https://sheet.best/api/sheets/8a012c90-b738-4cbf-a4f8-24fa56dba227';
  const onSubmit = (values) => {
    // console.log(values.name)
    const data = {
      Name: values.name,
      Email: values.email,
      Message: values.message,
    };
    axios.post(url, data).then(() => {
      reset();
      Swal.fire({
        icon: 'success',
        title: 'Thanks...',
        text: 'Your message has been received',
        confirmButtonColor: '#1066E0',
      });
    });
  };

  const {
    handleSubmit: handleSubscriptionSubmit,
    register: registerSubscription,
    formState: { errors: subscriptionError },
    reset: resetSubscription,
  } = useForm();
  const subscriptionUrl =
    'https://sheet.best/api/sheets/24607333-a54d-4233-ab5b-7d2576a63257';

  const onSubcriptionSubmit = (values) => {
    const data = {
      Email: values.email,
    };

    axios.post(subscriptionUrl, data).then(() => {
      resetSubscription();
      Swal.fire({
        icon: 'success',
        title: 'Thanks...',
        text: 'Your subscription has been received!',
        confirmButtonColor: '#1066E0',
      });
    });
  };
  return (
    <footer className='bg-lightBlue text-white overflow-hidden'>
      <div className='flex flex-col-reverse md:flex-row gap-10 md:justify-around md:items-center py-14 px-14 md:px-0'>
        <div className=' text-center md:text-start'>
          <div className='grid place-content-center md:place-content-start'>
            <a href='/'>
              <img src={Logo} alt='logo' />
            </a>
            <p className='uppercase font-semibold text-[10px]'>
              Recruitment Agency
            </p>
          </div>

          <div className='mt-10 md:w-60'>
            <p className='font-bold pb-5'>Location</p>
            <p>
              5B Queen's Park Estate, Eneka Road Port Harcourt, Rivers State,
              Nigeria.
            </p>
          </div>
          <div className='mt-6'>
            <h6 className='font-bold pb-5'>Subscribe Now</h6>
            <p>Newsletter and latest happenings around us.</p>
            <form
              onSubmit={handleSubscriptionSubmit(onSubcriptionSubmit)}
              className='mt-10'
            >
              <div className='flex'>
                <input
                  type='email'
                  {...registerSubscription('email', {
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                  placeholder='Email address'
                  className='w-full outline-none border bg-transparent border-white placeholder:text-white placeholder:font-semibold p-2'
                />
                <button
                  type='submit'
                  className='bg-darkblue  px-4 text-white font-bold hover:bg-white  hover:text-blue duration-300'
                >
                  Subscribe
                </button>
              </div>
              <span className=' text-red'>
                {' '}
                {subscriptionError.email && subscriptionError.email.message}
              </span>
            </form>
            <div className='flex gap-3 mb-10 mt-14 md:mb-20 place-content-center md:place-content-start'>
              <a
                href='https://twitter.com/firanigeria'
                target={'_blank'}
                rel='noreferrer noopener'
                className='w-10 h-10 p-2 text-2xl bg-blue text-white rounded-full cursor-pointer hover:-translate-y-1 hover:scale-110 hover:bg-white  hover:text-blue duration-300'
              >
                <FaTwitter />
              </a>
              <a
                href='https://www.linkedin.com/company/firanigeria/'
                target={'_blank'}
                rel='noreferrer noopener'
                className='w-10 h-10 p-2 text-2xl bg-blue text-white rounded-full cursor-pointer hover:-translate-y-1 hover:scale-110 hover:bg-white  hover:text-blue duration-300'
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>

        <div className=''>
          <p className='font-bold text-lg mb-6 text-center'>Contact Us</p>
          <p className=' font-normal md:font-semibold text-sm  md:text-xs mb-6 text-center'>
            Have questions? Drop a message below and we’ll get back to you
          </p>
          <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
            <input
              type='name'
              {...register('name', {
                required: 'Full name is required',
                minLength: { value: 3, message: 'Your fullname is too short' },
              })}
              placeholder='Name'
              className='w-full outline-none rounded-full bg-formBlue  placeholder:text-white placeholder:font-medium text-xs p-4'
            />
            <span className=' text-red'>
              {' '}
              {errors.name && errors.name.message}
            </span>
            <input
              type='email'
              {...register('email', {
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
              placeholder='Email address'
              className='w-full outline-none rounded-full  bg-formBlue placeholder:text-white placeholder:font-medium text-xs p-4 '
            />
            <span className=' text-red'>
              {' '}
              {errors.email && errors.email.message}
            </span>
            <textarea
              {...register('message', {
                required: 'Message is required',
              })}
              placeholder='Message'
              rows='8'
              className='w-full outline-none rounded-2xl bg-formBlue placeholder:text-white placeholder:font-medium text-xs p-2 '
            />
            <span className=' text-red'>
              {' '}
              {errors.message && errors.message.message}
            </span>
            <div className='grid place-content-center '>
              <button
                type='submit'
                className=' font-semibold hover:text-blue text-base rounded-2xl bg-formBlue hover:bg-white py-2 px-10'
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>

      <p className='text-center font-semibold mb-10'>
        2024 &#169; All rights reserved - Firadant Ltd.
      </p>
    </footer>
  );
};

export default Footer;
